<template>
  <section class="form-section">
    <div class="container">
      <div class="row">
        <div class="col-12">

       
         <b-overlay :show="!isLogin" rounded="sm">
        <div class="heading-center">
            <h2>PTE Registration Collection Partner</h2>
        <p>Think Higher is the official PTE Registration Collection Partner in Pakistan. We can assist in booking your tests online without any hassle. You don’t need to go down to the test centre in this COVID19 Pandemic station to book your test. Think Higher is offering great discounts on IELTS and PTE Coaching. We also provide IELTS and PTE test Registrations to AEO and Pearson Test Centres All Over Pakistan</p>
        </div>
        <form  @submit.prevent="handleSubmit" id="form" class="contact-comments">
            <div class="card">
                <div class="card-body">
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="name">Name</label>
                                <!-- Name -->
                                <input :disabled="'true'" v-model="formData.first_name" type="text" name="name" id="name" class=" form-control" placeholder="Name" maxlength="100" required="">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="email">Email <span class="text-danger">*</span></label>
                                <!-- Email -->
                                <input :disabled="'true'" v-model="formData.email_id" type="email" name="email" id="email" class=" form-control" placeholder="Email" maxlength="100" required="">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="phone">Phone</label>
                                
                                <input v-model="formData.phone" type="text" name="phone" id="phone" class=" form-control" placeholder="Phone" maxlength="100">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="address">Address <span class="text-danger">*</span></label>
                                
                                <input v-model="formData.address" type="text" name="address" id="address" class=" form-control" placeholder="Address *" maxlength="100"
                                v-validate="'required'"
                                :class="{'input': true, 'is-danger': errors.has('address') }">
                                 <span v-show="errors.has('address')" class="help is-danger">{{ errors.first('address') }}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="test_type">Test Type</label>
                                <!-- test type -->
                                <select v-model="formData.test_Type" class="form-control" name="test_type" id="test_type">
                                    <option value="null">--Select--</option>
                                    <option value="PTE">PTE</option>
                                    <option value="IELTS">IELTS</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="pte_previously">Have you appeared in pte previously? <span class="text-danger">*</span></label>
                                <!-- test type -->
                                <select  v-model="formData.previouslyPte" class="form-control" name="pte_previously" id="pte_previously">
                                    <option value="null">--Select--</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="desired_score">What is your  desired score?<span class="text-danger">*</span></label>
                                
                                <input v-model="formData.desiredScore" type="text" name="desired_score" id="desired_score" class=" form-control" maxlength="100"
                                 v-validate="'required'"
                                :class="{'input': true, 'is-danger': errors.has('desired_score') }">
                                 <span v-show="errors.has('desired_score')" class="help is-danger">The field desired score is required</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="test_date" title="(please put tentative date if test is not booked yet)">Test Date<span class="text-danger">*</span></label>
                                
                                <input data-date-format="DD-MM-YYYY" v-model="formData.testDate" type="date" name="test_date" id="test_date" class=" form-control" maxlength="100" 
                                v-validate="'required'"
                                :class="{'input': true, 'is-danger': errors.has('test_date') }">
                                 <span v-show="errors.has('test_date')" class="help is-danger">The field test date is required</span>
                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="form-group">
                                <label for="file">Upload your Previous Score file</label>
                                
                                <input type="file" name="file" id="file" class=" form-control">
                            </div>
                        </div> -->
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="which_intake">Which Intake You are After ?</label>
                                
                                <select v-model="formData.intakeAfter" class="form-control" name="inTake" id="inTake">
                                    <option value="null">--Select--</option>
                                    <option value="In-Week">In a week's time</option>
                                    <option value="In-Month">In a Month's Time</option>
                                    <option value="Asap">Asap</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="reference">Reference if any?</label>
                                <input v-model="formData.reference" type="text" name="reference" id="reference" class=" form-control" maxlength="100">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="prevision_scores">Your Previous scores</label>
                                <b-button :disabled="!isLogin" id="prevision_scores" @click="PreviousScore()" style="display: block;width: 100%;" variant="outline-info">Add Score</b-button>
                            </div>
                            
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="current_visa">Your current visa?</label>
                                <input v-model="formData.currentVisa" type="text" name="current_visa" id="current_visa" class=" form-control" placeholder="visa 500">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="test_type">How did you find us?</label>
                                <select v-model="formData.findUs" class="form-control" name="test_type" id="test_type">
                                    <option value="null">--Select--</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="LinkedIn">LinkedIn</option>
                                    <option value="Tweeter">Tweeter</option>
                                    <option value="Youtube">Youtube</option>
                                    <option value="Newsletter">Newsletter</option>
                                    <option value="Call Center">Call Center</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6 mt-2">
                        <div class="row pull-right">
                            <div class="col-md-3" v-if="isLogin">
                                <button type="submit" class="btn-save">{{buttonText}}</button>
                            </div>
                            <div v-else class="col-12">
                                <a class="signUp" @click="SingUp()">
                                    You need to Sign up with PTE Classes.
                                </a>
                            </div>
                    </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </form>
        <template #overlay>
        <div class="text-center">
          <b-icon icon="shield-lock-fill" font-scale="5" variant="outline-info" animation="cylon"></b-icon>
          <p id="cancel-label">You need to Sign up with PTE Classes....</p>
        </div>
      </template>
    </b-overlay>
     </div>
      </div>
    </div>
    
<b-modal centered id="setTarget" ref="setTarget" :title="modalData.title">
    <template>
      <div class="row">
        <div class="col-12 mb-1">
          <b-form-group
            label="OverAll"
            label-for="input-overAll"
            class="mb-0">
            <b-form-input
              id="input-overAll"
              v-model="modalData.overAll"
               v-on:keypress="isNumber"
               v-validate="'required|max_value:90|min_value:0'"
              placeholder="OverAll"
              type="number"
              min="1" max="90"
           name="overAll"
              :class="{'input': true, 'is-danger': errors.has('overAll') }"
            ></b-form-input>
            <span v-show="errors.has('overAll')" class="help is-danger">Overall value must be number and less than 90</span>
        </b-form-group>
        <!-- <b-form-input type="number" v-model="target.overAll" id="swal-input1" class="" placeholder="Overall"></b-form-input> -->
        </div>
        <div class="col-12 mb-1">
          <b-form-group
            label="Listening"
            label-for="input-listening"
            class="mb-0">
            <b-form-input
              id="input-listening"
              v-model="modalData.listening"
               v-on:keypress="isNumber"
              type="number"
              placeholder="Listening"
              v-validate="'required|max_value:90|min_value:0'"
              min="1" max="90"
            name="listening"
              :class="{'input': true, 'is-danger': errors.has('listening') }"
            ></b-form-input>
            <span v-show="errors.has('listening')" class="help is-danger">Listening value must be number and less than 90</span>
        </b-form-group>
        </div>
        <div class="col-12 mb-1">
          <b-form-group
            label="Reading"
            label-for="input-reading"
            class="mb-0">
            <b-form-input
              id="input-reading"
              v-model="modalData.reading"
               v-on:keypress="isNumber"
               v-validate="'required|max_value:90|min_value:0'"
              type="number"
              placeholder="Reading"
              min="1" max="90"
            name="reading"
              :class="{'input': true, 'is-danger': errors.has('reading') }"
            ></b-form-input>
            <span v-show="errors.has('reading')" class="help is-danger">Reading value must be number and less than 90</span>
        </b-form-group>
        </div>
        <div class="col-12 mb-1">
          <b-form-group
            label="Speaking"
            label-for="input-speaking"
            class="mb-0">
            <b-form-input
              id="input-speaking"
              v-model="modalData.speaking"
               v-on:keypress="isNumber"
               v-validate="'required|max_value:90|min_value:0'"
              type="number"
              placeholder="Speaking"
              min="1" max="90"
           name="speaking"
              :class="{'input': true, 'is-danger': errors.has('speaking') }"
            ></b-form-input>
            <span v-show="errors.has('speaking')" class="help is-danger">Speaking value must be number and less than 90</span>
        </b-form-group>
        </div>
        <div class="col-12 mb-1">
          <b-form-group
            label="Writing"
            label-for="input-writing"
            class="mb-0">
            <b-form-input
              id="input-writing"
              v-model="modalData.writing"
               v-on:keypress="isNumber"
               v-validate="'required|max_value:90|min_value:0'"
              type="number"
              placeholder="Writing"
              min="1" max="90"
            name="writing"
              :class="{'input': true, 'is-danger': errors.has('writing') }"
            ></b-form-input>
            <span v-show="errors.has('writing')" class="help is-danger">Writing value must be number and less than 90</span>
        </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group
            label="Exam Date"
            label-for="input-exam_dte"
            class="mb-0 label-datepicker">
            <!-- <b-form-datepicker
              id="input-exam_dte"
              v-model="modalData.exam_dte"
              placeholder="Exam Date"
            name="exam-date"
               v-validate="'required'"
               :class="{'input': true, 'is-danger': errors.has('exam-date') }"
            ></b-form-datepicker> -->
            <date-picker v-model="modalData.exam_dte" value-type="format" format="YYYY-MM-DD"></date-picker>

            <span v-show="errors.has('writing')" class="help is-danger">Exam date is required</span>
        </b-form-group>
        </div>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <!-- Emulate built in modal footer ok and cancel button actions -->
      <b-button size="md" variant="outline-success" @click="saveTarget()">
        Save
      </b-button>
      <b-button size="md" variant="outline-danger" @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
  </section>
</template>
<script>
import $ from 'jquery'
import { mapActions,mapGetters } from 'vuex'
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  components: { DatePicker },
  data () {
    return {
        isLogin:false,
        modalData:{},
        buttonText:'Save',
        formData:{
            user_id:0,
            first_name:'',
            email_id:'',
            phone:'',
            address:'',
            test_Type:null,
            previouslyPte:null,
            desiredScore:'',
            testDate:null,
            scoreFile:null,
            intakeAfter:'',
            reference:'',
            currentVisa:'',
            findUs:null,
            url:'users/insertUpdateCoachingUser'
        },
        target:{
        title:'Set Target Score',
        reading:84,
        writing:84,
        listening:84,
        speaking:84,
        overAll:84,
        exam_dte:'',
        user_id:0,
        result_card_path:'',
        coachingUserInfo:null,
        url:'configtarger/createtarget'
      },
    }
  },
  methods:{
      ...mapActions({
            fetchQuestionPractice:'auth/fetchGraphData',
            SaveDashBoard:'auth/saveDashBoardData',
            fileUpload:'speakmodule/uploadFile',
            UserCoachingUserInfofetch:'auth/fetchUserPersonalInfo',
        }),
        ...mapGetters({
            GetQuestionPractice:'auth/getPracticeNumber',
            GetDashBoardResponse:'auth/getDashBoardResponse',
            getUserCoachingUserInfo: 'auth/getPersonalInfo',
        }),
      SingUp(){
          $('.loginLink a').click()
      },
      fetchTarget(){
      const url='configtarger/id='+this.target.user_id
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                if(response!=null){
                  if(response.success==1 & response.data!=null){
                      //console.log(response.data)
                      this.target.overAll=response.data.overall
                      this.target.speaking=response.data.speaking
                      this.target.reading=response.data.reading
                      this.target.writing=response.data.writing
                      this.target.listening=response.data.listening
                      this.target.exam_dte=response.data.exam_dte
                  }
                }
            })
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
      var key = evt.keyCode || evt.which;
      key = String.fromCharCode(key);
      //console.log(key)
      return true;
      }
    },
    changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("ddd, MMMM Do YYYY");
          }
          else if(dateType === "shortDate"){
             return moment(date1).format("YYYY-MM-DD");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
    saveTarget(){
      this.$validator.validateAll().then((result) => {
        if (result) {
            this.modalData.exam_dte=this.changeDate(this.modalData.exam_dte,'shortDate')
            this.SaveDashBoard(this.modalData).then(()=>{
              const response=this.GetDashBoardResponse()
              if(this.modalType=='exam'){
                var data={
                      fileName:'ExamReport--',
                      files:this.loadedFile
                  }
                data.fileName+= this.modalData.user_id
                this.fileUpload(data)
              }
              this.$refs['setTarget'].hide()
              //console.log(response)
            })
      }
      else{
         alert('Correct them errors!');
      }
       
      });
    },
    fetchUserCoachingUserInfo(){
          this.UserCoachingUserInfofetch('users/coachingUser/id='+this.formData.user_id).then(()=>{
            var response = this.getUserCoachingUserInfo();
            this.coachingUserInfo=response.data
            //console.log(this.coachingUserInfo)
            if(response.success===1)
            {
                this.formData.phone=this.coachingUserInfo.phone
                this.formData.address=this.coachingUserInfo.address
                this.formData.test_Type=this.coachingUserInfo.test_Type
                this.formData.reference=this.coachingUserInfo.reference
                this.formData.scoreFile=this.coachingUserInfo.scoreFile
                this.formData.desiredScore=this.coachingUserInfo.desiredScore
                this.formData.findUs=this.coachingUserInfo.findUs
                this.formData.intakeAfter=this.coachingUserInfo.intakeAfter
                this.formData.currentVisa=this.coachingUserInfo.currentVisa
                this.formData.previouslyPte=this.coachingUserInfo.previouslyPte
                //console.log(this.formData)
                this.formData.testDate=this.changeDate(this.coachingUserInfo.testDate,'shortDate')
                this.buttonText="Update"
            }
          });
        },
    PreviousScore(){
        this.modalData=this.target
        this.$refs['setTarget'].show()
    },
    handleSubmit(){
      if(this.formData.address=='' | this.formData.desiredScore=='' |this.formData.desiredScore==0 |this.formData.previouslyPte ==null |
      this.formData.testDate=='')
      {
        return this.$alert('You need to fill all the required fields.')
      }
      else{
        this.SaveDashBoard(this.formData).then(()=>{
            const response=this.GetDashBoardResponse()
            if(response.success==1){
                return this.$alert('Record saved successfully')
            }
        })
      }
        
    }
  },
  mounted(){
      this.isLogin=(this.$ls.get('user')!=null)?true:false;
      if(this.isLogin){
          var tempUser=this.$ls.get('user')
          this.formData.user_id=tempUser.user_id
          this.formData.email_id=tempUser.email_id
          this.formData.first_name=tempUser.first_name
          this.target.user_id=tempUser.user_id
          this.fetchTarget()
          this.fetchUserCoachingUserInfo()
      }
  }
}
</script>
<style scoped>
.signUp{
    color: tomato!important;
}
.form-section{
    float: left;
    width: 100%;
    padding: 80px 0;
}
.form-section .heading-center{
    padding: 0;
    margin-bottom: 30px;
}
button.btn-save{
    float: left;
    color: #fff;
    /* font: 600 18px/45px 'Montserrat'; */
    font: 600 14px/35px 'Montserrat'!important;
    padding: 0 43px;
    background: #1e467f;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #f23276;
    border-radius: 50px;
    padding: 8px 32px!important;
    box-shadow: 2px 4px 0px grey;
    letter-spacing: 2px;
    color: #fff!important;
}
button.btn-save:hover{
    color: #fff!important;
    text-decoration: none;
    box-shadow: none;
    /* background: #001948; */
}
.b-overlay.position-absolute {
    position: absolute!important;
    width: 100%;
    height: 100%;
}
.label-datepicker
{
    display: block!important;
}
.mx-datepicker
{
    width: 100%;
    border-color: #d0d6db;
}
</style>