<template>
     <div class="admin-section coachingPlans">
         <div class="app-main__inner">
         <div class="row">
          <div class="col-md-12 fullwidth">
              <div class="app-page-title dashboard-title">
                  <div class="page-title-wrapper">
                      <div class="page-title-heading">
                          <div class="page-title-icon">
                              <i class="pe-7s-medal icon-gradient bg-happy-itmeo">
                              </i>
                          </div>
                          <div>Coaching Plans</div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
         <div class="row">
             <div class="col-12 mt-1 settings fullwidth">
                 <div class="main-card mb-3 card">
                <div class="card-body ml-4 mr-4">
                <app-BookClass/>
                </div>
                </div>
             </div>
         </div>
         <div class="row userProfile m-3 fullwidth">
        <app-coachingPlans/>
        </div>
         </div>
     </div>
</template>
<script>
import coachingPlans from '../coaching/CoachingPriceSection'
import BookClass from '../coaching/BookClassFormSection'
export default {
    components:{
        'app-coachingPlans':coachingPlans,
        'app-BookClass':BookClass
    }
}
</script>
<style scoped>
.userProfile .price-table
{
    background: none!important;
    padding-top: 10px!important;
}
.form-section
{
  padding: 10px 0!important;
}
</style>
<style>
.admin-section h2{
font-size: 24px;
color: #1e467f !important;
padding-bottom: 20px;
}
.admin-section .heading-center p {
    /* font: 14px 'Montserrat'; */
    font: 14px 'Montserrat'!important;
}
.userProfile.m-3 .price-table .box{
    width: 95%;
}
</style>