<template>
  <!--Pricing Table Section Start-->
  <section class="price-table">
    <div class="container">
      <div class="heading-center">
        <h2>Select A Plan</h2>
      </div>
      <div class="holder">
        <div class="row"> 
          <div class="offset-xl-3 offset-lg-3 col-xl-6 col-lg-6 col-md-12 fullwidth">
            <div :class="{'default': userCoachingPackage =='1-Month'}" class="box" v-on:mouseenter="defaultActive = false" v-on:mouseleave="defaultActive = true">
              <div class="head">
                <h3>Pro Master Practice Class</h3>
                <span class="price">$20/Month</span></div>
                <ul>
                  <li><b>Twice a Month</b> </li>
                  <li><b>How to use templates: </b>Yes</li>
                <li><b>In class practice: </b> Yes</li>
                 </ul>
              <a v-if="userCoachingPackage!='1-Month'" @click="toggleModal('1-Month')" id="Month-1" class="btn-get">GET STARTED</a>
              <a v-if="userCoachingPackage =='1-Month'"  @click="unSubscription()" class="btn-get">Unsubscribe</a></div>
          </div>
          <!-- <div  class="col-xl-3 col-lg-6 col-md-12 fullwidth">
            <div  :class="{'default': userCoachingPackage =='3-Month'}" class="box" v-on:mouseenter="defaultActive = false" v-on:mouseleave="defaultActive = true">
              <div  class="head">
                <h3>3 Months</h3>
                <span class="price">$249/3 Month</span></div>
                <ul>
                  <li><b>Unlimited Classes</b> </li>
                 </ul>
              <a  v-if="userCoachingPackage!='3-Month'" @click="toggleModal('3-Month')" id="Month-3" class="btn-get">GET STARTED</a>
              <a v-if="userCoachingPackage =='3-Month'"  @click="unSubscription()" class="btn-get">Unsubscribe</a></div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-12 fullwidth">
            <div :class="{'default': userCoachingPackage =='6-Month'}" class="box" v-on:mouseenter="defaultActive = false" v-on:mouseleave="defaultActive = true">
              <div class="head">
                <h3>6 Months</h3>
                <span class="price">$399/6 Month</span></div>
                <ul>
                  <li><b>Unlimited Classes</b> </li>
                 </ul>
              <a v-if="userCoachingPackage!='6-Month'" @click="toggleModal('6-Month')" id="Month-6" class="btn-get">GET STARTED</a>
              <a v-if="userCoachingPackage =='6-Month'"  @click="unSubscription()" class="btn-get">Unsubscribe</a></div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-12 fullwidth">
            <div :class="{'default': userCoachingPackage =='12-Month'}" class="box" v-on:mouseenter="defaultActive = false" v-on:mouseleave="defaultActive = true">
              <div class="head">
                <h3>12 Months</h3>
                <span class="price">$699/12 Month</span></div>
                 <ul>
                  <li><b>Unlimited Classes</b> </li>
                 </ul>
              <a v-if="userCoachingPackage!='12-Month'" @click="toggleModal('12-Month')" id="Month-12" class="btn-get">GET STARTED</a>
              <a v-if="userCoachingPackage =='12-Month'"  @click="unSubscription()" class="btn-get">Unsubscribe</a></div>
          </div> -->
        </div>
      </div>
      <b-modal ref="payment-Modal" id="paymentModel" no-close-on-backdrop hide-footer hide-header centered>
        <b-button class="closeModalbutton" @click="$bvModal.hide('paymentModel')">X</b-button>
        <b-overlay class="fixedHeight mt-3" :show="show" rounded="sm" >
      <div class="mb-3 text-center">
        <h3 class="">Select Payment Gateway</h3>
      </div>
      <b-button class="mb-3" variant="outline-primary" block @click="stripAPIPayment">
        <img src="@/assets/images/Stripelg.png" />
      </b-button>
      <b-button class="paypalButton" variant="outline-primary" block @click="payPalPayment">
        <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_200x51.png" alt="PayPal" />
        </b-button>
        <b-button class="paypalButton" variant="success" block @click="directPayment">
        Direct Bank Transfer / RAAST
        </b-button>
        <template #overlay>
        <div class="text-center">
          <b-icon icon="cloud-upload-fil" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Processing...</p>
        </div>
      </template>
    </b-overlay>
    </b-modal>
    <b-modal size="xl" v-model="detailshow" id="bankDetailModel" no-close-on-backdrop hide-footer hide-header centered>
        <b-button class="closeModalbutton" @click="detailshow=false" >X</b-button>
        <template>
          <table class="bankDetail">
  <tr>
    <th>TITLE</th>
    <th>DESCRIPTION</th>
  </tr>
  <tr>
    <td class="bld">TH Office</td>
    <td>Karachi Branch</td>
  </tr>
  <tr>
    <td class="bld">Account Name</td>
    <td>Aneel Khowaja</td>
  </tr>
  <tr>
    <td class="bld">IBAN</td>
    <td>PK27BAHL1004098106625701</td>
  </tr>
  <tr>
    <td class="bld">Account No</td>
    <td>1004098106625701</td>
  </tr>
  <tr>
    <td class="bld">Bank Name</td>
    <td>Bank Al Habib</td>
  </tr>
  <tr>
    <td class="bld">Bank Address</td>
    <td>1004-GULSHAN-E-IQBAL</td>
  </tr>
  <tr>
    <td class="bld">Swift Code</td>
    <td>BAHLPKKA</td>
  </tr>
  <tr>
    <td class="bld">RAAST ID</td>
    <td>01433217177</td>
  </tr>
  <tr>
    <td class="bld">IBAN</td>
    <td>PK27BAHL1004098106625701</td>
  </tr>
  <tr>
    <td class="bld">E-Mail / WhatsApp</td>
    <td>Please Mail your receipt at <strong>info@pteclasses.com</strong> or share transfer receipt at <strong>+92-302-2556108</strong> for the payment confirmation.</td>
  </tr>
  
          </table>
        </template>
        
    </b-modal>
    </div>
  </section>
  <!--Pricing Table Section End-->
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
import { mapActions,mapGetters } from 'vuex';
export default {
  data () {
    return {
      defaultActive: true,
      show:false,
      detailshow:false,
      currentPackage:'',
      user:null,
      price_id:'',
      currentSelectedType:'',
      userCoachingPackage:'',
      disabled:true,//as per user instruction
      changePaymentType:''
    }
  },
  methods:{
    ...mapActions({
            updateUser: 'auth/updateUserType',
            stripActions: 'auth/stripActions',
            removeSession: 'auth/removeSession',
            reFetchUser:'auth/fetchUser',
            paypalQuery:'paypal/postPayPalQuery'
      }),
      ...mapGetters({
         getUser:'auth/user',
         paypalResponse:'paypal/getPayPalResponse'
      }),
      directPayment(){
      this.$refs['payment-Modal'].hide()
      this.detailshow=true
   },
    toggleModal(type) {
      this.changePaymentType=''
      this.currentSelectedType=type
     if(this.user!=null)this.currentPackage=this.user.coaching
      if(this.user == null){
        return this.$alert(
                            "Please create you account first or login to proceed.",
                            "Warning",
                            "warning",
                            {
                                confirmButtonText: "OK"
                            })
        //alert('Please create you account first or login to proceed')
      }
      else if(this.currentPackage !='No' & this.currentPackage!=null){
        return this.$confirm("Are you sure you want to change your subscription?",
                            "Confirmation",
                            "question",
                            {
                                confirmButtonText: "Confirm"
                            }).then(()=>{
                              
                              this.togglePayment(type)
                            })
      }
      else{
        //this.$ls.set('CoachingPackageType',type)
        this.togglePayment(type)
      }
    },
  togglePayment(type){
    if(this.currentSelectedType=='') this.currentSelectedType=type
    ////console.log(this.price_id)
    //////debugger
     this.$refs['payment-Modal'].toggle('#Month-1')
     this.$refs['payment-Modal'].toggle('#Month-3')
     this.$refs['payment-Modal'].toggle('#Month-6')
     this.$refs['payment-Modal'].toggle('#Month-12')
  },
  async updateSubScription(){
    if(this.changePaymentType=='Stripe')
        {
           this.show=true
          this.$confirm("Your current coaching subscription is on PayPal, if you select Stripe payment method, your PayPal coaching subsription will be cancelled?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Confirm"
                              }).then(()=>{
                                this.payPalCancelSub('renewal')
                              }).catch(() => {
                                this.show=false
                                //console.log('cancel')
                              });
        }
        else{
        var vm=this
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/update-subscription", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            priceId: vm.price_id,
            subscriptionId:vm.user.coaching_stripe_subscription,
          })
        }).then(function(result) {
          vm.$refs['payment-Modal'].hide()
          ////console.log(result.json())
          result.json().then((res)=>{
            var data={
                    coaching_payment_type:'Stripe',
                    coaching_amount:res.subscription.plan.amount,
                    url:'users/updateChangeCoachingStripePackage',
                    user_id: vm.user.user_id,
                    coaching:vm.currentSelectedType,
                    coaching_payment_date:null,
                    coaching_stripe_membership:JSON.stringify(res),
                    email:vm.user.email_id,
                    userName:vm.user.first_name
                }
          //console.log(data)
          vm.updateUser(data).then(()=>{
            var userData={
                      email_id:data.email
                      }
                      vm.userCoachingPackage=''
                        vm.reFetchUser(userData).then(()=>{
                          vm.user= vm.$ls.get('user')
                          ////console.log(user)
                          if(vm.user!=null){
                                vm.userCoachingPackage=vm.user.coaching
                              }
                          window.location.reload()
                        })
                    ////console.log(response)
                })
          
          })
          
        });
        }
      },
    handleFetchResult(result) {
        //console.log(result)
        if (!result.ok) {
          return result.json().then(function(json) {
            if (json.error && json.error.message) {
              //console.log(result.url + ' ' + result.status + ' ' + json.error.message);
            }
          }).catch(function(err) {
            //console.log(err);
          });
        }
        return result.json();
      },
    stripAPIPayment(){
        this.show=true
        if(this.user.coaching_payment_type=="PayPal" & this.changePaymentType=='')
        {
          this.changePaymentType='Stripe'
        }
        if(this.currentSelectedType=='1-Month') 
        {
          this.price_id=process.env.VUE_APP_ONE_MONTH_COACHIG
        }
        else if(this.currentSelectedType=='3-Month'){
          this.price_id=process.env.VUE_APP_THREE_MONTH_COACHIG
        }
        else if(this.currentSelectedType=='6-Month'){
          this.price_id=process.env.VUE_APP_SIX_MONTH_COACHIG
        }
        else if(this.currentSelectedType=='12-Month'){
          this.price_id=process.env.VUE_APP_12_MONTH_COACHIG
        }
        if(this.user.coaching_payment_type=='Stripe' | this.changePaymentType=='Stripe'){
          return this.updateSubScription()
        }
        var vm=this;
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/setup")
        .then(this.handleFetchResult)
        .then(function(res) {
          ////console.log(res)
          
          vm.stripeSetUpResponse=res
          var stripe = Stripe(vm.stripeSetUpResponse.publishableKey);
          vm.createCheckoutSession(vm.price_id).then(function(data) {
            stripe
              .redirectToCheckout({
                sessionId: data.sessionId
              })
              .then(function(result) {
                //vm.show=false
                  //console.log(result)
                if (result.error) {
                  vm.$alert(
                      result.error.message,
                      "Error",
                      "error",
                      {
                        confirmButtonText: "Issue in Payment Process"
                      }
                    )
                  // alert(result.error.message);
                }
              })
              .catch(function(error) {
                this.show=false
                console.error('Error:', error);
              })
          });
          //return //console.log(result.json());
        });
      },
    createCheckoutSession(priceId) {
      ////debugger
      this.$ls.set('CoachingPackageType',this.currentSelectedType)
        //process.env.VUE_APP_BASE_URL+
        var vm=this
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/create-checkout-session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            priceId: priceId,
            customer_email:vm.user.email_id,
            customer:vm.user.stripe_customerId,
            payType:'coaching'
          })
        }).then(function(result) {
          return result.json();
        });
      },
    unSubscription(){
        this.$confirm(
              "Please confirm your unsubscribe request",
              "Unsubscribe",
              "question",
              {
                confirmButtonText: "Confirm"
              }
            ).then(()=>{
              if(this.user.coaching_payment_type=='PayPal')
              {
                return this.payPalCancelSub()
              }
              var vm=this
              return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/cancel-subscription",{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  subscriptionId: vm.user.coaching_stripe_subscription
                })
                }).then((results)=>{
                      vm.cancelsubUpdateUser(results.subscription,'unsubscribe')
                })
              })
      },
    cancelsubUpdateUser(result,type){
            var data={
                coaching_payment_type:'No',
                userName:this.user.first_name,
                email:this.user.email_id,
                user_id: this.user.user_id,
                coaching:'No',
                url:'users/updateUserCoachingType',
                coaching_payment_cancel:null,
                coaching_cancel_membership:JSON.stringify(result)
              }
              this.updateUser(data).then(()=>{
                var userData={
                email_id:data.email
                }
                this.userCoachingPackage=''
                  this.reFetchUser(userData).then(()=>{
                    this.user= this.$ls.get('user')
                    if(this.user!=null){
                        this.userCoachingPackage=this.user.coaching
                        if(type=='unsubscribe'){
                          window.location.reload()
                        }
                        else if(type=='PayPalchange')
                        {
                          this.payPalPayment()
                        }
                        else if(type=='stripeChange')
                        {
                          this.stripAPIPayment()
                        }
                      }
                  })
              })
    },
    updatePayPalPayment(){
      ////debugger
      if(this.changePaymentType=="PayPal")
        {
          this.show=true
          this.$confirm("Your current coaching subscription is on Stripe, if you select PayPal payment method your Stripe coaching subsription will be cancelled?",
                              "Confirmation",
                              "question",
                              {
                                confirmButtonText: "Confirm"
                              }).then(()=>{
                                  var vm=this
                                    return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/cancel-subscription",{
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json"
                                      },
                                      body: JSON.stringify({
                                        subscriptionId: vm.user.coaching_stripe_subscription
                                      })
                                      }).then((results)=>{
                                          vm.changePaymentType='Free'
                                          vm.cancelsubUpdateUser(results.subscription,'PayPalchange')
                                      })
                              }).catch(() => {
                                this.show=false
                                //console.log('cancel')
                              });
        }
        else{
      var data={
        url:'payPalPayment/get-token'
      }
        var vm=this
        this.show=true
        vm.paypalQuery(data).then(()=>{
        let tokenResponse=vm.paypalResponse();
        if(tokenResponse.status==200){
          var updateSubdata={
            url:'payPalPayment/update-subscription',
            planType:vm.currentSelectedType,
            planMode:'live',
            paypal_subscription_id:vm.user.coaching_stripe_customerId
          }
          vm.paypalQuery(updateSubdata).then(()=>{
            let updateResponse=vm.paypalResponse()
            if(updateResponse.status==200){
                var updateUserdata={
                    coaching_payment_type:'PayPal',
                    coaching_amount:vm.$ls.get('packageAmount'),
                    url:'users/updateChangeCoachingStripePackage',
                    user_id: vm.user.user_id,
                    coaching:vm.currentSelectedType,
                    coaching_payment_date:null,
                    coaching_stripe_membership:JSON.stringify(updateResponse.response),
                    email:vm.user.email_id,
                    userName:vm.user.first_name
                }
                //console.log(updateUserdata)
                vm.updateUser(updateUserdata).then(()=>{
                    var userData={
                              email_id:data.email
                              }
                    vm.userCoachingPackage=''
                      vm.reFetchUser(userData).then(()=>{
                        vm.user= vm.$ls.get('user')
                        if(vm.user!=null){
                        vm.userCoachingPackage=vm.user.coaching
                        }
                        window.location.reload()
                      })
                          ////console.log(response)
                  })
          
            }
          });
        }
      });
        }
    },
    payPalPayment(){
      ////debugger
      this.show=true
      if(this.user.coaching_payment_type=="Stripe" & this.changePaymentType=='' )
        {
          this.changePaymentType='PayPal'
        }
      if(this.currentSelectedType=='1-Month') 
        {
          this.$ls.set('packageAmount',199)
        }
        else if(this.currentSelectedType=='3-Month'){
          this.$ls.set('packageAmount',249)
        }
        else if(this.currentSelectedType=='6-Month'){
          this.$ls.set('packageAmount',399)
        }
        else if(this.currentSelectedType=='12-Month'){
          this.$ls.set('packageAmount',699)
        }
        if(this.user.coaching_payment_type=='PayPal' | this.changePaymentType=='PayPal'){
          return this.updatePayPalPayment()
        }
      var data={
        url:'payPalPayment/get-token'
      }
      var vm=this
      vm.show=true
      vm.paypalQuery(data).then(()=>{
        let tokenResponse=vm.paypalResponse();
        if(tokenResponse.status==200){
          var subdata={
            url:'payPalPayment/subscribe-now',
            planType:vm.currentSelectedType,
            planMode:'live',
            first_name:vm.user.first_name,
            last_name:vm.user.last_name,
            email_id:vm.user.email_id
          }
            vm.paypalQuery(subdata).then(()=>{
              //vm.show=false
              let subResponse=vm.paypalResponse().response
              //console.log(subResponse)
                if(subResponse.status=='APPROVAL_PENDING'){
                  vm.$ls.set('paypalReturn',subResponse)
                  const redirectLink=subResponse.links[0].href
                  //console.log(redirectLink)
                  window.open(redirectLink,"_self")
                }
            })
        }
      })
    },
    payPalCancelSub(status){
      var data={
        url:'payPalPayment/get-token'
      }
      var vm=this
      vm.paypalQuery(data).then(()=>{
        let tokenResponse=vm.paypalResponse();
        if(tokenResponse.status==200){
          var canceldata={
            url:'payPalPayment/cancel-subscription',
            planType:vm.currentSelectedType,
            planMode:'live',
            paypal_subscription_id:vm.user.coaching_stripe_customerId
          }
          vm.paypalQuery(canceldata).then(()=>{
            let cancelResponse=vm.paypalResponse()
            if(cancelResponse.status==200)
            {
              if(status=='cancel'){
              vm.cancelsubUpdateUser(cancelResponse.response,'unsubscribe')
              //window.location.reload()
              }
              else{
                this.changePaymentType='Free'
                this.user_type='Free'
                vm.cancelsubUpdateUser(cancelResponse.response,'strieChange')
              }
            }
          })
        }
      });
    }
  },
  beforeMount(){
    this.user = this.$ls.get('user')
    if(this.user!=null){
      this.userCoachingPackage=this.user.coaching
    }
  }
}
</script>
<style scoped>
.box.default {
  border-color: transparent;
  background: #1e467f;
  transform: scale(1.1);
  z-index: 9;
  position: relative;
}

.price-table .heading-center {
  padding: 0;
  margin-bottom: 30px;
}

.price-table .box.default a.btn-get {
  border-color: transparent;
  background: #f23276;
    border-radius: 50px;
    padding: 8px 15px;
    box-shadow: 2px 4px 0px grey;
}

.box.default ul li b, .price-table .box.default h3, .box.default ul li, .box.default .price {
  color: #fff !important;
}

.box.default {
  border-color: transparent;
  background: #1e467f;
  transform: scale(1.1);
  z-index: 9;
  position: relative;
}

.price-table .box.default a.btn-get {
  border-color: transparent;
  color: #fff;
  background: #f23276;
    border-radius: 50px;
    padding: 8px 15px;
    box-shadow: 2px 4px 0px grey;
}

.box.default ul li b, .price-table .box.default h3, .box.default ul li, .box.default .price {
  color: #fff !important;
}
#paymentModel img
{
  width: 35%;
}
/* #paymentModel button{
  background: none!important;
} */
.paypalButton
{
  padding-top: 20px!important;
  padding-bottom: 20px!important;

}
.closeModalbutton, .closeModalbutton:hover,.closeModalbutton:active,.closeModalbutton:focus{
  color: #f30f00;
    padding: 5px!important;
    line-height: 10px;
    font-weight: 800;
    position: absolute;
    right: 10px;
    top: 10px;
    border-color: #f30f00;
    z-index:5;
}
table.bankDetail  {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size:14px;
}
.bankDetail td,.bankDetail th {
  border: 1px solid #000;
  text-align: left;
  padding: 8px;
  min-width:150px;
  
}
.bankDetail td.bld{
font-weight: 600;
}
.bankDetail th{
font-size:16px!important;
}
.bankDetail tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
